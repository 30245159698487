import CVNavbar from './components/CVNavbar'
import Home from './components/Home'
import AboutUs from './components/AboutUs'
import Sections from './components/Sections'
import ContactUs from './components/ContactUs'

function App() {
  return (
    <>
      <CVNavbar />
      <Home />
      <ContactUs />
      {/*
      <AboutUs />
      <Sections />
      
      */}
    </>
  );
}

export default App;
