import 'bootstrap/dist/css/bootstrap.min.css';
import CVLogo from '../img/logo.png'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import NavbarBrand from 'react-bootstrap/esm/NavbarBrand';


export default function CVNavbar() {
  return (
    <Navbar expand="md" sticky='top' data-bs-theme="dark" className="bg-navbar-custom navbar-shadow">
      <Container>
        <div className='d-flex flex-column align-items-center'>
          <Navbar.Brand href="#top">
            <img src={CVLogo} alt="logo" className="logo"/>
          </Navbar.Brand>
          <a href="tel:4799684417">
            <button className='btn btn-primary'>Call: (479) 968-4417</button>
          </a>
        </div>
        
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto w-100 justify-content-end">
            
            <Nav.Link href="#top">Home</Nav.Link>
            {/*
            <Nav.Link href="#aboutus">About Us</Nav.Link>
            <Nav.Link href="#sections">Sections</Nav.Link>
            */}
            <Nav.Link href="#contactus">Contact Us</Nav.Link>
            
          </Nav>
        </Navbar.Collapse>
        
      </Container>
    </Navbar>
  );
}