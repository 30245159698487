import SeasonFallImage from '../img/fall.png'


export default function Home(){
    return(
        <>
            <section className="section-home d-flex align-items-center justify-content-center">
                <div className="py-5 mx-3">
                    <div className="col-12 col-md-10 col-lg-8 mx-auto bg-hero-custom p-5">
                        <p className="lead me-4 text-light">
                            A perpetual care cemetery nestled in the Arkansas River 
                            Valley countryside, Center Valley Memorial Gardens amenities 
                            include professionally manicured grounds with automated sprinkler 
                            system to ensure lush greens for all of our clients.  CVMG also 
                            includes our exclusive "Field of Honor" for veterans and their 
                            spouse.  We have a number of options for gravesites from our 
                            "Horizon" single spaces to our Family Garden areas which can be 
                            designed to your specification including hedged shrubs and walkway.
                        </p>
                        {/*
                        <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
                            <a href="#sections">
                                <button type="button" className="btn btn-primary btn-lg px-4 gap-3">Learn More</button>
                            </a>
                        </div>
                        */}
                    </div>
                </div>
            </section>
        </>
    )
}