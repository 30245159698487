import SeasonFallImage from '../img/fall.png'

export default function ContactUs(){
    return(
        <>
            <section id="contactus" className="p-4 section-contactus d-flex flex-column justify-content-center align-items-center text-light">
                <div className="col-12 text-center">
                    <h3>Ask us a question</h3>
                </div>
                <div className="col-12 text-center">
                    <h4 className="pt-4">Email</h4>
                    <p>
                        <a href="mailto:contact@humphreyfuneral.com">contact@humphreyfuneral.com</a>
                    </p>
                </div>
            </section>
        </>
    )
}